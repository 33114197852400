* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #f8f9fa;
  overflow-y: hidden;
}

.MenuSidebar {
  background-color: #222222 !important;
  padding: 0px !important;
}

.MenuSidebar .p-panelmenu a {
  border: none !important;
  color: white !important;
  background: transparent !important;
  border-radius: none !important;
}

.layout-footer {
  position: fixed;
  font-size: 0.65rem;
  width: 100%;
  bottom: 0;
  background-color: #222222;
  color: white;
  text-align: center;
}

.login-page .logo {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #222222;
  color: white;

  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 20.8px !important;
  line-height: 22.4px !important;
  font-size: 2.08rem !important;
  line-height: 2.24rem !important;
  color: #fff;
  display: block;
  flex-basis: 40%;
  font-weight: 500;
  letter-spacing: -0.025em;
  white-space: nowrap;
  padding-left: 30px;
}

@media (min-width: 600px) {
  .login-page .logo {
    font-size: 26px !important;
    line-height: 28px !important;
    font-size: 2.6rem !important;
    line-height: 2.8rem !important;
    -webkit-box-flex: 1;
    flex: 1;
  }
}

.login-page .logo svg {
  display: inline-block;
  fill: #fff;
  width: 112px;
}

.login-page span.divider {
  background-color: #fff;
  display: inline-block;
  height: 35px;
  margin-right: 8px;
  width: 1px;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 15px;
}

.menu-bar-title {
  color: white;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.3rem;
  text-decoration: none;
}

.MenuSidebar .p-panelmenu .p-panelmenu-content {
  border: none !important;
  background: #222222 !important;
  color: white !important;
}

.MenuSidebar .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-text,
.p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: white !important;
}

.menu-bar {
  background-color: #222222 !important;
}

.MenuSidebar
  .p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: white;
}

.mr-2 {
  margin-right: 10px;
}

.panelTitle {
  color: white;
  border-bottom-style: solid;
  border-bottom-width: thin;
  padding: 10px;
  margin: 10px;
  border-bottom-color: rgb(252, 237, 0);
}

.MenuSidebar .p-menuitem-icon,
.MenuSidebar
  .p-panelmenu
  .p-panelmenu-content
  .p-menuitem
  .p-menuitem-link
  .p-menuitem-icon {
  color: #00aeef;
}

.panelTitle .panelSubTitle {
  color: white;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-decoration: none;
  padding-left: 10px;
  vertical-align: super;
}

.assets p-panel .p-panel-header {
  background: #393939 !important;
  color: white !important;
  border: none;
  font-size: large;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.assets .project-item .product-item-content {
  border: 1px solid var(--surface-d);
  border-radius: 3px;
  margin: 0.3rem;
  text-align: center;
  padding: 2rem 0;
}

.assets .project-item .project-image {
  width: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.assets .p-card-footer,
.projects .p-card-footer {
  text-align: center !important;
}

.projects .p-card-footer .p-button.p-button-icon-only.p-button-rounded,
.assets .p-card-footer .p-button.p-button-icon-only.p-button-rounded {
  margin: 5px;
}

.projects .p-card .p-card-subtitle {
  color: #00aeef !important;
}

.assets .p-panel .p-panel-content {
  background-color: #393939;
  border: none;
}

.assets .p-panel .p-card .p-card-body {
  /* background-color: #393939; */

  color: black;
  border-radius: 3%;
  border-width: 1px;
  border-style: solid;
  border-color: white;
}

.assets .p-card .p-card-title,
.assets .p-card .p-card-title {
  color: #00aeef;
}

.assets p-panel .p-panel-header {
  border: none;
  font-size: large;
}

.assets .p-card .p-card-subtitle {
  color: #689f38;
}
.assets p-button,
.projects p-button {
  margin-right: 30px;
}

.services .p-panel .p-panel-content {
  padding: 0px;
}

.p-password .p-inputtext {
  width: 22rem !important;
}

.managment .p-card .p-card-content,
.managment .p-card .p-card-body {
  padding-top: 0px !important;
}
.spinner {
  background-color: #22222263;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 9000;
}

.spinner .p-progress-spinner {
  top: 40%;
  left: 50%;
}

.managment-form .p-dropdown-label,
.managment-form .p-multiselect-label {
  font-size: large;
  min-width: 300px;
}

.p-tag {
  font-size: inherit !important;
  font-weight: normal !important;
}

.managment .p-chips,
.managment .p-chips .p-chips-multiple-container {
  min-width: 100%;
}

.MenuSidebar {
  background-color: #222222 !important;
  padding: 0px !important;
}

p-password .p-inputtext {
  width: 100% !important;
}

.ng-valid[required],
.ng-valid.required {
  border-left: 5px solid #42a948; /* green */
}

.ng-invalid:not(form) {
  border-left: 5px solid #a94442;
}

table tbody tr td {
  overflow-wrap: anywhere !important;
}

.formbody .p-inputtext,
.formbody .p-calendar,
.formbody .p-dropdown {
  width: 500px !important;
}
.filter-form .p-inputtext,
.filter-form .p-multiselect,
.filter-form .p-calendar {
  width: 200px !important;
}

.filter-form .p-dropdown {
  width: 140px !important;
}

/* .formbody .ng-invalid:not(form) {
  border-left: 1px solid #ced4da;
} */

.formbody p-inputnumber.ng-invalid:not(form) {
  border-left: none !important;
}
.formbody .ng-invalid:not(form) .p-inputnumber-input {
  border-left: 5px solid #a94442;
}

.data-table-blugins .p-listbox .p-listbox-list .p-listbox-item {
  margin-left: 30px !important;
}
/* ------------------------start margin----------------------------------------------- */
.mr-3 {
  margin-right: 1rem;
}

.ml-3 {
  margin-left: 1rem;
}

.m-3 {
  margin: 1rem;
}

.m-3 {
  margin: 1rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.m-4 {
  margin: 1.5rem;
}
/* ------------------------end  margin----------------------------------------------- */
.p-overlay-badge .p-badge {
  position: fixed !important;
  top: inherit !important;
  right: inherit !important;
  transform: translate(90%, -50%) !important;
}

 .p-badge {
  background: #f8f9fa!important;
  color: #62bb46!important;
}


::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background:transparent;
  
}

::-webkit-scrollbar-thumb {
  background: #8ca1b6;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(241, 241, 241, 0.75);
}

::-webkit-scrollbar-corner {
  background: rgb(219, 217, 217);
}

::-webkit-scrollbar-track{

  background-color: inherit;
}